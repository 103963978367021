
import { defineComponent, onMounted, ref } from "vue";
import Graph from "@/components/graph/Graph.vue";
import Activity from "@/views/crafted/pages/profile/Activity.vue";
import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
import TableWidget5 from "@/components/widgets/tables/Widget5.vue";
import ListWidget1 from "@/components/widgets/lists/Widget1.vue";
import ListWidget2 from "@/components/widgets/lists/Widget2.vue";
import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
import ListWidget25 from "@/components/dashboard/Widget5.vue";
import ListWidget6 from "@/components/widgets/lists/Widget6.vue";
import MixedWidget2 from "@/components/dashboard/Widget2.vue";
import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getCountOfData } from "@/core/data/dashboard-data";
// import VueApexCharts from "vue3-apexcharts";
import moment from 'moment';
import PieChart from "@/components/pieChart/PieChart.vue";

export default defineComponent({
  name: "dashboard",
  components: {
    // CalendarApp1
    // apexcharts: VueApexCharts,
    Graph,
    PieChart
},
  setup() {
    const dateRangeValue = ref("");
    let pickerOptions = {
      shortcuts: [
        {
          text: "Last week",
          onClick: (picker) => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "Last month",
          onClick: (picker) => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "Last 3 months",
          onClick: (picker) => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
    };
    const totalCount = ref<any>({});
    const defaultTime = ref([
      new Date(2000, 1, 1, 0, 0, 0),
      new Date(2000, 2, 1, 23, 59, 59),
    ]);
    const getLatestCount = function () {
      return new Promise((res, rej) => {
        getCountOfData().then((resObj: any) => {
          totalCount.value = resObj.data;
        });
      });
    };
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      getLatestCount();
    });
    return {
      defaultTime,
      dateRangeValue,
      pickerOptions,
      totalCount
    };
  },
});
