
import { defineComponent, onMounted, ref } from "vue";
import VueApexCharts from "vue3-apexcharts";
import { getLast6MonthsCount } from "@/core/data/dashboard-data";
import moment from "moment";

export default defineComponent({
  name: "Graph",
  components: {
    apexcharts: VueApexCharts,
  },
  props: {
    title: String,
    moduleName: String,
  },
  setup(props) {
    const isLoading = ref<boolean>(false);

    const chartOptions = ref<any>({
      chart: {
        id: "basic-bar",
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [],
      },
    })
    const series = ref<any>([
      {
        name: "",
        data: [],
      },
    ])
    onMounted(() => {
      getLastMonthData();
    });

    const getLastMonthData = () => {
      isLoading.value = true;
      return new Promise((res, rej) => {
        getLast6MonthsCount(props.moduleName)
          .then((resObj: any) => {
            resObj.data.map(
              (objItem) => (objItem.month = moment(objItem.month).format("MMM"))
            );
            isLoading.value = false;
            const monthCount = 6;
            let categoriesData: any = [];
            let plotData: any = [];
            for (let i = 1; i <= monthCount; i++) {
              var currentDate = moment().add(1, "M").subtract(i, "M");
              let formattedMonth = currentDate.format("MMM");
              categoriesData.push(formattedMonth);
              let dataCheck = resObj.data.find(
                (item) => item.month == formattedMonth
              );
              if (dataCheck === undefined) {
                plotData.push(0);
              } else {
                plotData.push(dataCheck.count);
              }
            }

            series.value = [
              {
                name: "",
                data: plotData.reverse(),
              },
            ];
            chartOptions.value = {
              ...chartOptions.value,
              xaxis: {
                categories: categoriesData.reverse(),
              },
            };
          })
          .catch((err) => {
            console.log("Error is ", err);
            isLoading.value = false;
          });
      });
    };

    return {
      isLoading,
      getLastMonthData,
      chartOptions,
      series
    };
  },
});
