
import { defineComponent, onMounted, ref } from "vue";
import VueApexCharts from "vue3-apexcharts";
import { getLast6MonthsCount, getProductionStatus } from "@/core/data/dashboard-data";
import moment from "moment";

export default defineComponent({
  name: "PieChart",
  components: {
    apexcharts: VueApexCharts,
  },
  setup() {
    const isLoading = ref<boolean>(false);

    const chartOptions = ref<any>({
      chart: {
        id: "donut-production",
        type: "donut",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        responsive: [{
            breakpoint: 480,
            options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
            }
        }]
      },
    labels: ['Apple', 'Mango', 'Orange', 'Watermelon']
    })
    const series = ref<any>([44, 55, 41, 17])
    onMounted(() => {
      getProductionData();
    });

    const getProductionData = () => {
      isLoading.value = true;
      return new Promise((res, rej) => {
        getProductionStatus()
          .then((resObj: any) => {
            isLoading.value = false;
            const labelsList:any = []
            const seriesList :any = []
            resObj.data.forEach((item)=>{
                seriesList.push(item.count)
                if(item.status == null || item.status == undefined){
                    labelsList.push("Status not specified")
                }else{
                    labelsList.push(item.status)
                }
            })
           
           chartOptions.value = {
            ...chartOptions.value,
            labels: labelsList
           }

           series.value = seriesList

          })
          .catch((err) => {
            console.log("Error is ", err);
            isLoading.value = false;
          });
      });
    };

    return {
      isLoading,
      getProductionStatus,
      chartOptions,
      series
    };
  },
});
